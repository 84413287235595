<template>

    <div v-if="mount" class="home_background flex flex-col w-full" style="background-repeat: no-repeat; background-size: cover; height: 100%;">

        <div class="h-24 flex flex-col relative">

            <div class="h-full flex flex-row justify-start items-center p-2">

                <img src="/images/logoEy_old.svg" class="h-14">
                <span class="font-semibold ml-2 text-lg">Executive Dashboard</span>

            </div>

            <div class="absolute bottom-0 right-0 h-8 flex-none w-full flex flex-row justify-end items-center px-3">

                <div class="text-right mr-2">
                    <div class="text-xxs">{{ userData.user_name }}</div>
                    <div class="bg-violet rounded-sm text-3xs px-2 py-1px">
                        {{ globalParamsPretty.role_type_name }}
                        <template v-if="globalParamsPretty.role_type_link !== 'presidencia'">
                            / {{ globalParamsPretty.gui_role_name }}
                        </template>
                    </div>
                </div>

                <img v-show="profilePhoto" class="h-6 w-6 rounded-full bg-cover bg-center shadow-xl" :src="profilePhoto" @error="imageError"/>

            </div>

        </div>

        <div class="flex-1 min-h-0 w-full flex flex-col px-2">

            <div class="h-32 w-full flex">

                <div class="h-full w-1/2 p-1 relative">
                    <!-- <div v-if="!seeit" class="absolute top-0 left-0 h-full w-full z-20 rounded-lg" style="backdrop-filter: blur(10px);"></div> -->
                    <PipelineHomeMobile class="h-full w-full"/>
                </div>

                <div class="h-full w-1/2 p-1 relative">
                    <!-- <div v-if="!seeit" class="absolute top-0 left-0 h-full w-full z-20 rounded-lg" style="backdrop-filter: blur(10px);"></div> -->
                    <ProjectsManagementHomeMobile class="h-full w-full"/>
                </div>

            </div>

            <div class="w-full flex" :class="globalParamsPretty.role_type_link  !== 'EP' ? 'h-32' : 'h-0 overflow-hidden'">

                <div class="h-full relative" :class="(globalParamsPretty.role_type_link  !== 'gcspClient' && globalParamsPretty.role_type_link  !== 'sectorLeader') ? 'w-1/2 p-1' : 'w-0'">
                    <!-- <div v-if="!seeit" class="absolute top-0 left-0 h-full w-full z-20 rounded-lg" style="backdrop-filter: blur(10px);"></div> -->
                    <ChargeabilityHomeMobile v-if="globalParamsPretty.role_type_link  !== 'gcspClient' && globalParamsPretty.role_type_link  !== 'sectorLeader'" class="h-full w-full"/>
                </div>

                <div class="h-full p-1 relative" :class="(globalParamsPretty.role_type_link  !== 'gcspClient' && globalParamsPretty.role_type_link  !== 'sectorLeader') ? 'w-1/2' : 'w-full'">
                    <!-- <div v-if="!seeit" class="absolute top-0 left-0 h-full w-full z-20 rounded-lg" style="backdrop-filter: blur(10px);"></div> -->
                    <InterIntraHomeMobile class="h-full w-full" />
                </div>

            </div>

            <div class="flex-1 min-h-0 w-full">

                <div class="h-full w-full p-1">
                    <PersonalDashboardHomeMobile class="h-full w-full"/>
                </div>

            </div>

        </div>

    </div>

</template>

<script>

const PipelineHomeMobile = () => import("@/components/Mobile/Home/Pipeline");
const ProjectsManagementHomeMobile = () => import("@/components/Mobile/Home/ProjectsManagement");
const ChargeabilityHomeMobile = () => import("@/components/Mobile/Home/Chargeability");
const InterIntraHomeMobile = () => import("@/components/Mobile/Home/InterIntra");
const PersonalDashboardHomeMobile = () => import("@/components/Mobile/Home/PersonalDashboard");

import { state, actions } from '@/store';
const Filters = () => import( '@/components/Globals/Filters.vue');

export default {
    components: {
        PersonalDashboardHomeMobile,
        InterIntraHomeMobile,
        ChargeabilityHomeMobile,
        PipelineHomeMobile,
        ProjectsManagementHomeMobile,
        Filters
    },
    data(){
        return{
            openFilters: false,
            mount: false
        }
    },
    computed:{
        imageProfile(){ 
            return process.env.VUE_APP_IMG_PROFILE 
        },
        userProfile(){
            return process.env.VUE_APP_IMG_USER 
        },
        userData(){
            return state.user 
        },
        user(){
            return state.user 
        },
        profilePhoto() {
            return process.env.VUE_APP_AUTH_IMG_USER + state.user.profile_photo;
        },
        defaultPhoto() {
            return process.env.VUE_APP_AUTH_IMG_USER + 'PersonPlaceholder.96x96x32.png'
        },
        globalParamsPretty(){

            if(Object.keys(state.globalParamsPretty).length > 0){
            
                return state.globalParamsPretty.rol

            } else {
                
                return state.user.role_default

            }
        },
        path(){
            return this.$route.path.split('/')[2] 
        },
        isLoadingRegister(){
            return state.isLoadingRegister;
        },
        seeit(){
            if(state.rolSelected.role_type_link != 'presidencia'){
                return true
            } else {
                return false
            }
        },
        paramsQuery(){
            return this.$route.query.seeDataAs
        }
    },
    methods: {
        imageError(event) {
            event.target.src = this.defaultPhoto;
        },
    },
    watch:{
        paramsQuery(n,o){
            
            if(n == undefined){
                // this.$router.go().catch(failure => {
                // if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
                //     // show a small notification to the user
                //     showToast('Login in order to access the admin panel')
                // }
                // });
                // setTimeout(() => {
                //     this.$router.go();
                    
                // }, 5000);
                // window.location.reload();
            }
        }
    },
    mounted() {

        if (state.user.screen == 3) {
            this.$router.push({path:'HomePersonalDashboard'}) 
        } else {
            this.mount = true;
        }

    }
}

</script>

<style>

    .home_background {
        background: url('/images/fondoEY.png') no-repeat fixed center center;
        opacity:.9;
    }


    @screen motoG4 {
        .pipeline, .projects, .chargeability, .interIntra {
            @apply h-28;
            width: 7.2rem;
        }
        .personal {
            @apply h-36 w-full
        }
    }

    @screen iphoneX {
        .pipeline, .projects, .chargeability, .interIntra {
            @apply h-34 w-30;
        }
        .personal {
            @apply h-36 w-full
        }
    }

    @screen iphone678 {
        .pipeline, .projects, .chargeability, .interIntra {
            @apply h-30 w-30;
        }
        .personal {
            @apply h-36 w-full
        }
    }

    @screen iphone678Plus {
        .pipeline, .projects, .chargeability, .interIntra {
            @apply h-34 w-34;
        }
        .personal {
            @apply h-36 w-full
        }
    }

    @screen pixel2 {
        .pipeline, .projects, .chargeability, .interIntra {
            @apply h-34 w-34
        }
        .personal {
            @apply h-36 w-full
        }
    }

    @screen pixel2xl {
        .pipeline, .projects, .chargeability, .interIntra {
            @apply h-36 w-34
        }
        .personal {
            @apply h-36 w-full
        }
    }



</style>